import axios from "axios";
export function request(config) {
  const instance = axios.create({
    // baseURL: "http://www.bizhi.com/index.php/admin/",
    baseURL: "https://service.zhoulikai.com/index.php/admin/",
    timeout: 5000,
  });
  //请求拦截
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (err) => {
      console.log(err);
    }
  );
  //响应拦截
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {
      // 如果没有授权， 去login
      // 如果有错误， 在这里可以提示
      console.log(err, 'sssss');
      return Promise.reject(err);
    }
  );
  return instance(config);
}