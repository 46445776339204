<template>
    <div>
        <div class="edit">
                <div>{{id}}</div>
                <input type="file" @change="uploadImg"> 
                <img :src="file_url">
                <div>
                    专辑标题：<textarea v-model="title" ></textarea>
                </div>
                <div>
                    专辑描述：<textarea v-model="des" ></textarea>
                </div>
                <div>浏览量:{{browser_num}}</div>
                <div>分享量:{{share_num}}</div>
                <div>收藏量:{{collect_num}}</div>
                <div>
                    是否热门:<input type="checkbox" v-model="hot">
                </div>
                <div>
                    是否在列表中:<input type="checkbox" v-model="status">
                </div>
                <div class="btn" @click="saveHandle">提交</div>
        </div>
        
    </div>
</template>

<script>
import {useRouter,useRoute} from "vue-router";
import { reactive, toRefs } from "vue";
// import { ElMessage } from 'element-plus'
import {Upload,AlbumInfo, EditAlbumInfo} from '../../network/index'
import { ElMessage } from 'element-plus'

export default({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id;
    console.log(id);
    const data = reactive({
      file_url:'',
      file: {},
      title:'',
      des: '',
      browser_num: 0,
      share_num: 0,
      collect_num: 0,
      hot: false,
      status: true,
      isChangeImg: false
    });

    const editAlbumHandler = (img) => {
        console.log(img);
        if (data.title == '') {
            ElMessage.error('title不能为空');
            return;
        }
        const param = {
            id: id,
            title: data.title,
            des: data.des,
            img: img,
            hot: data.hot ? 1 : 0,
            status: data.status ? 1 : 0
        };
        EditAlbumInfo(param).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('编辑成功');
                router.go(-1);
            } else {
                ElMessage.error(res.message);
            }
        });
    }

    const saveHandle = ()=> {
        console.log('编辑保存');
        if (data.isChangeImg) {
            //上传图片
            let formData = new FormData();
            formData.append('file', data.file);
            Upload(formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('上传成功');
                editAlbumHandler(res.data.file_path);
            } else {
                ElMessage.error(res.message);
            }
            
            });
        } else {
            editAlbumHandler(data.file_url);
        }
    }
    AlbumInfo({id: id}).then((res) => {
        console.log(res);
        if (res.code == 1) {
            data.file_url = res.data.img;
            data.id = res.data.id;
            data.title = res.data.title;
            data.des = res.data.des;
            data.browser_num = res.data.browser_num;
            data.share_num = res.data.share_num;
            data.collect_num = res.data.collect_num;
            data.hot = res.data.hot == 1;
            data.status = res.data.status == 1;
        } else {
            ElMessage.error(res.message);
        }
    }).catch((res) => {
        console.log(res);
        ElMessage.error('请求错误');
    });

    const uploadImg = (e) => {
        const file = e.target.files[0];
        data.file = file;
        console.log('上传图片', file);
        // e.target.files[0];
        data.file_url = URL.createObjectURL(file);
        data.isChangeImg = true;
    }

    return {
        saveHandle,
        uploadImg,
      ...toRefs(data),
    }
  }
  
  });

</script>

<style scoped>
.edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #333333;
}

.edit img{
    width: 100px;
}

.edit textarea {
    width: 300px;
    height: 100px;
    margin-top: 10px;
}
.edit .btn {
    height: 40px;
    width: 300px;
    background: #5db45e;
    border-radius: 10px;
    margin: 10px;
    line-height: 40px;
    color: white;
    font-size: 14px;
    cursor: pointer;
} 
</style>