<template>
    <div>
        <div class="edit">
                <div>{{id}}</div>
                <input type="file" @change="uploadImg"> 
                <img :src="file_url">
                <div>
                    banner表题：<textarea v-model="title" ></textarea>
                </div>
                
                <div class="type">   
                    banner类型
                    <input type="radio" v-model="type" value="1"/> <label>专辑</label>  
                    <input type="radio" v-model="type" value="2"/> <label>壁纸</label>  
                    <input type="radio" v-model="type" value="3"/> <label>url链接</label>  
                </div>

                 <div style="height:10px"></div>
                <div class="album" v-show="type == 1">
                    请选择专辑
                    <select v-model="album_id">
                        <option disabled value="">请选择专辑</option>
                        <option v-for="(item, index, key) in albumList" :key="key" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>

                </div>

                <div class="wallpaper" v-show="type == 2">
                    请选择壁纸
                    <select v-model="wallpaper_id">
                        <option disabled value="">请选择壁纸</option>
                        <option v-for="(item, index, key) in wallpaperList" :key="key" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>

                </div>

                <div class="banner_url" v-show="type == 3">
                    请输入链接：<input type="text" v-model="url">
                </div>

                <div>
                    是否在列表中:<input type="checkbox" v-model="status">
                </div>
                <div class="btn" @click="saveHandle">提交</div>
        </div>
        
    </div>
</template>

<script>
import {useRouter,useRoute} from "vue-router";
import { reactive, toRefs } from "vue";
// import { ElMessage } from 'element-plus'
import {Upload,BannerInfo,AlbumList,WallpaperList, EditBannerInfo} from '../../network/index'
import { ElMessage } from 'element-plus'

export default({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id;
    console.log(id);
    const data = reactive({
      file_url:'',
      file: {},
      title:'',
      status: true,
      isChangeImg: false,
      type: 1,
      album_id: -1,
      albumList:[],
      wallpaper_id: -1,
      wallpaperList:[],
      url:"",
    });

    const editBannerHandler = (img) => {
        console.log(img);
        if (data.title == '') {
            ElMessage.error('title不能为空');
            return;
        }
        const param = {
            id: id,
            title: data.title,
            type: data.type,
            album_id: data.album_id,
            wallpaper_id: data.wallpaper_id,
            url: data.url,
            status: data.status ? 1 : 0,
            img: img,
            
        };
        EditBannerInfo(param).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('编辑成功');
                router.go(-1);
            } else {
                ElMessage.error(res.message);
            }
        });
    }

    const saveHandle = ()=> {
        console.log('编辑保存');
        if (data.isChangeImg) {
            //上传图片
            let formData = new FormData();
            formData.append('file', data.file);
            Upload(formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('上传成功');
                editBannerHandler(res.data.file_path);
            } else {
                ElMessage.error(res.message);
            }
            
            });
        } else {
            editBannerHandler(data.file_url);
        }
    }

    //专辑列表
    AlbumList({status: 1}).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    data.albumList = res.data;
                } else {
                    ElMessage.error(res.message);
                }
    });
    //壁纸列表
    WallpaperList({status: 1, allFlag: 1}).then((res) => {
        if (res.code == 1) {
            data.wallpaperList = res.data;
        } else {
            ElMessage.error(res.message);
        }
    });
    //banner列表
    BannerInfo({id: id}).then((res) => {
        console.log(res);
        if (res.code == 1) {
            data.file_url = res.data.img;
            data.id = res.data.id;
            data.title = res.data.title;
            data.type = res.data.type;
            data.album_id = res.data.album_id;
            data.wallpaper_id = res.data.wallpaper_id;
            data.url = res.data.url;
            data.status = res.data.status == 1;
        } else {
            ElMessage.error(res.message);
        }
    }).catch((res) => {
        console.log(res);
        ElMessage.error('请求错误');
    });

    const uploadImg = (e) => {
        const file = e.target.files[0];
        data.file = file;
        console.log('上传图片', file);
        // e.target.files[0];
        data.file_url = URL.createObjectURL(file);
        data.isChangeImg = true;
    }

    return {
        saveHandle,
        uploadImg,
      ...toRefs(data),
    }
  }
  
  });

</script>

<style scoped>
.edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #333333;
}

.edit img{
    width: 100px;
}

.edit textarea {
    width: 300px;
    height: 100px;
    margin-top: 10px;
}
.edit .btn {
    height: 40px;
    width: 300px;
    background: #5db45e;
    border-radius: 10px;
    margin: 10px;
    line-height: 40px;
    color: white;
    font-size: 14px;
    cursor: pointer;
} 
</style>