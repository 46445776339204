<template>
    <div class="root">
        <div class="left">
            <div :class="current_pos == 0 ? 'active' : ''" @click="changeBtn(0)">壁纸列表</div>
            <div :class="current_pos == 1 ? 'active' : ''" @click="changeBtn(1)">添加</div>
        </div>

        <div class="right">
            <div class="list" v-show="current_pos == 0">
                <el-switch v-model="status" @change="changeStatus(status)"/> {{status ? '列表' : '回收站'}}
                <div class="content">
                    <div class="item" v-for="(item, index, key) in wallpaperList" :key="key">
                        <img :src="item.img" alt="">
                        <div class="title">{{item.name}}</div>
                        <div>浏览量:{{item.browser_num}}</div>
                        <div>分享量:{{item.share_num}}</div>
                        <div>收藏量:{{item.collect_num}}</div>
                        <div>来源: {{item.src_from == 0 ? '网络': '上传'}}</div>
                        <div :class="item.download_limit != 0 ? 'download_limit':'' ">下载限制：{{item.download_limit == 0 ? "完全不限制" : "受一天总次数限制"}}</div>
                        <div v-show="item.download_limit != 0">突破限制：{{item.break_limit == 0 ? "完全没办法" : item.break_limit == 1 ? "需要助力" : "视频广告"}}</div>
                        <div class="btn" type="primary" @click="editHandle(item)">修改</div>
                        
                        <!-- <div class="btn" type="primary">详情</div> -->
                        <div class="btn" type="primary" @click="recyleHandle(item.id)">{{status ? '放入回收站' : '移除回收站'}}</div>
                        <div class="btn" :class="item.recommend == 1 ? 'btn_active' : ''" @click="recommendHandle(item)">{{item.recommend == 1 ? '推荐' : '不推荐'}} </div>
                    </div>
                </div>
                <div class="more" @click="moreLoad">加载更多</div>
            </div>
            
            <div class="add" v-show="current_pos == 1">
                <input type="file" @change="uploadImg">
                <img :src="file_url">
                <div>
                    壁纸标题：<textarea v-model="name" ></textarea>
                </div>
                <div>
                    壁纸描述：<textarea v-model="desc" ></textarea>
                </div>

                <div class="album">
                    请选择专辑
                    <select v-model="album_id">
                        <option disabled value="">请选择专辑</option>
                        <option v-for="(item, index, key) in albumList" :key="key" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>

                </div>
                <div class="cls">
                    请选择分类
                    <select v-model="class_id">
                        <option disabled value="">请选择分类</option>
                        <option v-for="(item, index, key) in clsList" :key="key" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>

                </div>
                <div class="recommend">
                    是否推荐:<input type="checkbox" v-model="recommend">
                </div>

                <div class="download_limit">   
                    下载限制
                    <input type="radio" v-model="download_limit" value="0"/> <label>完全不限制</label>  
                    <input type="radio" v-model="download_limit" value="1"/> <label>受一天总次数影响</label>  
                </div>

                <div class="break_limit">   
                    突破限制方法
                    <input type="radio" v-model="break_limit" value="0"/> <label>完全没办法</label>  
                    <input type="radio" v-model="break_limit" value="1"/> <label>助力</label>  
                    <input type="radio" v-model="break_limit" value="2"/> <label>视频广告</label>  
                </div>
                
                <div class="btn" @click="saveHandle">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import { useRouter} from "vue-router";
import { reactive, toRefs } from "vue";
import { ElMessage } from 'element-plus'
import {Upload, AddWallpaper, AlbumList,WallpaperList, ClassList, WallpaperRecyle, WallpaperRecommend} from '../../network/index'

export default({
  setup() {
    const router = useRouter();
    const num = 30;
    const data = reactive({
      current_pos:0,
      file_url:'',
      file: {},
      name:"",
      desc:"",
      recommend:false,
      albumList: [],
      status: true,
      album_id: -1,
      class_id: -1,
      clsList:[],
      download_limit:1,
      break_limit:1,
      wallpaperList:[],
      page: 1,
    });
    const wallpaperListHandle = (isRefresh=true) => {
        if (isRefresh) data.page = 1;
        WallpaperList({
            status:data.status ? 1 : 0,
            page: data.page,
            num: num
        }).then((res) => {
            console.log(res);
            if (res.code == 1) {
                if (isRefresh) {
                    data.wallpaperList = res.data;
                } else {
                    if (res.data.length == 0) {
                        ElMessage.error('没有更多数据');
                        data.page = data.page - 1;
                    } else {
                        data.wallpaperList = [...data.wallpaperList, ...res.data];
                    }
                }
                
            } else {
                ElMessage.error(res.message);
            }
        });
    }
    const editHandle = (item) => {
        console.log(item);
        router.push('/wallpaperEdit?id='+ item.id);
    }

    const recommendHandle = (item) => {
        WallpaperRecommend({id: item.id, recommend: item.recommend == 1 ? 0 : 1}).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('操作成功');
                // wallpaperListHandle();
                item.recommend == 1 ? 0 : 1
            } else {
                ElMessage.error(res.message);
            }
        });
    };
    const recyleHandle = (id) => {
        console.log(data.status);
        WallpaperRecyle({id: id, status:!data.status ? 1 : 0}).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('操作成功');
                wallpaperListHandle();
            } else {
                ElMessage.error(res.message);
            }
        });
    }

    const changeStatus = (status) => {
        console.log('状态:', status);
        wallpaperListHandle();
    }

    
    const albumListHandler = () => {
            AlbumList({status:1}).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    data.albumList = res.data;
                } else {
                    ElMessage.error(res.message);
                }
            });
        }
    //专辑列表
    albumListHandler();
    //分类列表
    ClassList({status: 1}).then((res) => {
        if (res.code == 1) {
            data.clsList = res.data;
        } else {
            ElMessage.error(res.message);
        }
    });
    //壁纸列表
    wallpaperListHandle();
    const moreLoad = () => {
        data.page = data.page + 1
        wallpaperListHandle(false);
    }
    
    const saveHandle = () => {
        console.log('提交保存');
        if (data.file_url == '') {
            ElMessage.error('请上传图片');
            return;
        }
        if (data.name == '') {
            ElMessage.error('请输入标题');
        }
        const addWallpaperHandler = (img)=> {
            const param = {
                name: data.name,
                des: data.desc,
                img: img,
                class_id: data.class_id,
                album_id: data.album_id,
                recommend:data.recommend ? 1 : 0,
                src_from: 1,
                download_limit: data.download_limit,
                break_limit: data.break_limit
            };
            AddWallpaper(param).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    ElMessage.success('添加成功');
                    data.current_pos = 0;
                    data.status = true;
                    //更新数据列表
                    wallpaperListHandle();
                } else {
                    ElMessage.error(res.message);
                }
            });
        }
        //上传图片
        let formData = new FormData();
        formData.append('file', data.file);
        Upload(formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('上传成功');
                addWallpaperHandler(res.data.file_path);
            } else {
                ElMessage.error(res.message);
            }
            
            // data.img = e.data.file_path;
        });
    }
    const uploadImg = (e) => {
        const file = e.target.files[0];
        data.file = file;
        console.log('上传图片', file);
        // e.target.files[0];
        data.file_url = URL.createObjectURL(file);
        
    }
    const changeBtn = (pos)=> {
        data.current_pos = pos;
        if (data.current_pos == 0) {
            wallpaperListHandle();
        }
    }
    
    const token = window.localStorage.getItem('token');
    if (!token) {
          // alert('请重新登录');
          ElMessage.error('请重新登录');
          router.push('/login');
          // this.$router.push('/login');
          return;
    }
  
    return {
        moreLoad,
        editHandle,
        saveHandle,
        changeBtn,
        uploadImg,
        changeStatus,
        recyleHandle,
        recommendHandle,
      ...toRefs(data),
    }
  },
})
</script>

<style scoped>
.root {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    height: 100%;
}
.root .left {
    flex: 1;
    /* height: 100%; */
    background: #6fd3ce;
}

.root .left div {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.root .left .active {
    background: #5db45e;
}

.root .right {
    flex: 5;
    background: #cdffc3;
}
.root .right .add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #333333;
}

.root .right .list {
    margin: 10px;
}
.root .right .list .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.root .right .list .item {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid salmon;
    margin: 10px;
}

.root .right .list .item .btn {
    width: 100%;
    height: 30px;
    background: #5db45e;
    margin-top: 3px;
    line-height: 30px;
    color: white;
}

.root .right .list .item .btn_active {
    background: blueviolet;
}

.root .right .list .item img {
    width: 200px;
    height: 355px;
    overflow: hidden;
}

.root .right .list .item .title {
    margin-top: 10px;
    font-size: 12px;
    color: #333333;
    margin-bottom: 10px;
}

.root .right .add img{
    width: 100px;
}

.root .right .add textarea {
    width: 300px;
    height: 100px;
    margin-top: 10px;
}
.root .right .btn {
    height: 40px;
    width: 300px;
    background: #5db45e;
    border-radius: 10px;
    margin: 10px;
    line-height: 40px;
    color: white;
    font-size: 14px;
    cursor: pointer;
} 


.album {
    margin-top: 10px;
}

.cls {
    margin-top: 10px;
}

.download_limit {
    margin-top: 10px;
}

.recommend {
    margin-top: 10px;
}

.download_limit {
    color: red;
}
.more {
    width:100%;
    height:50px;
    background:red;
    color:white;
    line-height:50px;
    cursor: pointer;
}

</style>