import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/login/LoginView.vue'
import AlbumView from '../views/album/AlbumView.vue'
import ClassView from '../views/class/ClassView.vue'
import WallpaperView from '../views/wallpaper/WallpaperView.vue'
import BanderView from '../views/banner/BanderView.vue'
import AlbumEdit from '../views/album/AlbumEdit.vue'
import ClassEdit from '../views/class/ClassEdit.vue'
import WallpaperEdit from '../views/wallpaper/WallpaperEdit.vue'
import BannerEdit from '../views/banner/BannerEdit.vue'
const routes = [

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: "登录页面"
    }
  },
  {
    path: '/',
    alias: '/album',
    name: 'album',
    component: AlbumView,
    meta: {
      title: "专辑页面"
    }
  },
  {
    path: '/albumEdit',
    name: 'albumEdit',
    component: AlbumEdit,
    meta: {
      title: "专辑页面"
    }
  },
  {
    path: '/class',
    name: 'class',
    component: ClassView,
    meta: {
      title: "分类页面"
    }
  },
  {
    path: '/classEdit',
    name: 'classEdit',
    component: ClassEdit,
    meta: {
      title: "分类编辑"
    }
  },
  {
    path: '/wallpaper',
    name: 'wallpaper',
    component: WallpaperView,
    meta: {
      title: "壁纸页面"
    }
  },
  {
    path: '/wallpaperEdit',
    name: 'wallpaperEdit',
    component: WallpaperEdit,
    meta: {
      title: "壁纸编辑页面"
    }
  },
  {
    path: '/bander',
    name: 'bander',
    component: BanderView,
    meta: {
      title: "banner页面"
    }
  },

  {
    path: '/bannerEdit',
    name: 'bannerEdit',
    component: BannerEdit,
    meta: {
      title: "banner编辑页面"
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
