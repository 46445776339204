<template>
<div class="root">
  <div>
  <el-button v-if="$route.path == '/' ||
      $route.path == '/class' || $route.path == '/wallpaper' || $route.path == '/bander'" type="primary" @click="logout">退出系统</el-button>
  <nav v-if="$route.path == '/' ||
      $route.path == '/class' || $route.path == '/wallpaper'|| $route.path == '/bander'">
        <router-link :class="{ active: $route.path == '/' || $route.path == '/album' }" to="/">
          <span>专辑管理</span>
        </router-link>
        <router-link :class="{ active: $route.path == '/class' }" to="/class">
          <span>分类管理</span>
        </router-link>

        <router-link :class="{ active: $route.path == '/wallpaper' }" to="/wallpaper">
          <span>壁纸管理</span>
        </router-link>
        <router-link :class="{ active: $route.path == '/bander' }" to="/bander">
          <span>banner管理</span>
        </router-link>
        
    </nav> 
    
  </div>
  
    <div class="content">
      <router-view/>
    </div>
  
</div>
</template>


<script>

import { useRouter} from "vue-router";
import { reactive, toRefs } from "vue";
import { ElMessage } from 'element-plus'
export default({
  setup() {
    const router = useRouter();
    
    const data = reactive({
      
    });
    
    const token = window.localStorage.getItem('token');
    if (!token) {
          // alert('请重新登录');
          router.push('/login');
          // this.$router.push('/login');
          return;
    }

    const logout = () => {
      console.log('退出成功');
      window.localStorage.removeItem('token');
      ElMessage.success('退出成功');
      router.push('/login');
    }
  
    return{
      logout,
      ...toRefs(data),
    }
  },
})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div, body{
  margin: 0;
  padding: 0;
}
nav {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
  background: #e74c3e;
  line-height: 50px;
  border-radius: 10px;
  
}
nav a{
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
}
nav .active {
  background: #0d98ba;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.content dir {
  padding: 0;
  margin: 0;
}
.root {
  display: flex;
  flex-direction: column;
  // height: 100vh;
}
.content{
  margin-left: 50px;
  margin-right: 50px;
  flex: 1;
  background: #0d98ba;
  // height: 100%;
}

</style>
