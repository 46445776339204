import {request} from "./request.js";
const appid = "lc20220118";
const appkey = "u665698fzur5e2t85tyu142";
const CryptoJS = require("crypto-js");

function makeSign(data) {
    //添加必要的字段
    const token = window.localStorage.getItem('token');
    if (token && token != '') {
        data.token = token;
    }
    data.appid = appid;
    const randomNumber = Math.floor(Math.random() * 100000);
    data.nonce = randomNumber; 
    const timestamp = Math.floor(new Date().getTime() / 1000);
    data.timestamp = timestamp;
    const sortedData = Object.fromEntries(Object.entries(data).sort());
    console.log(sortedData);
    //按照key值排序，组成字符串 + key
    let buff = '';
    for (let key in sortedData) {
        if(key != "sign"){
            buff += key + "=" + sortedData[key] + "&";
        }
    }
    buff = buff.substring(0, buff.length - 1);
    buff = buff + "&key=" + appkey ;
    console.log(buff);
    const md5Value = CryptoJS.MD5(buff).toString();
    const $result = md5Value.toLowerCase();
    data.sign = $result;
}
function requestNet(url, method,data) {
   
    // console.log(data);
    makeSign(data);
    return request( {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method : method,
        url : url,
        data: data
    } )
}
export function test(data={}){
    return requestNet('Admin/test', 'POST', data);
}

export function login(data={}) {
    return requestNet('Admin/login', 'POST', data);
}


export function addAlbum(data={}) {
    return requestNet('Admin/addAlbum', 'POST', data);
}

export function AlbumList(data={}) {
    return requestNet('Admin/albumList', 'POST', data);
}

export function AlbumRecyle(data={}) {
    return requestNet('Admin/albumRecyle', 'POST', data);
}

export function AlbumHot(data={}) {
    return requestNet('Admin/albumHot', 'POST', data);
}

export function ClassList(data={}) {
    return requestNet('Admin/classList', 'POST', data);
}


export function AlbumInfo(data={}) {
    return requestNet('Admin/albumInfo', 'POST', data);
}
export function EditAlbumInfo(data={}) {
    return requestNet('Admin/editAlbum', 'POST', data);
}

export function addClass(data={}) {
    return requestNet('Admin/addClass', 'POST', data);
}


export function ClassRecyle(data={}) {
    return requestNet('Admin/classRecyle', 'POST', data);
}

export function ClassInfo(data={}) {
    return requestNet('Admin/classInfo', 'POST', data);
}

export function EditClassInfo(data={}) {
    return requestNet('Admin/editClass', 'POST', data);
}

export function AddWallpaper(data={}) {
    return requestNet('Admin/addWallpaper', 'POST', data);
}

export function WallpaperList(data={}) {
    return requestNet('Admin/wallpaperList', 'POST', data);
}

export function WallpaperRecyle(data={}) {
    return requestNet('Admin/wallpaperRecyle', 'POST', data);
}

export function WallpaperRecommend(data={}) {
    return requestNet('Admin/wallpaperRecommend', 'POST', data);
}

export function WallpaperInfo(data={}) {
    return requestNet('Admin/wallpaperInfo', 'POST', data);
}

export function EditWallpaperInfo(data={}) {
    return requestNet('Admin/editWallpaperInfo', 'POST', data);
}

export function addBanner(data={}) {
    return requestNet('Admin/addBanner', 'POST', data);
}

export function BannerList(data={}) {
    return requestNet('Admin/bannerList', 'POST', data);
}


export function BannerRecyle(data={}) {
    return requestNet('Admin/bannerRecyle', 'POST', data);
}

export function BannerInfo(data={}) {
    return requestNet('Admin/bannerInfo', 'POST', data);
}

export function EditBannerInfo(data={}) {
    return requestNet('Admin/editBannerInfo', 'POST', data);
}

export function Upload(data={}){
    return request({
        headers: {
            'Content-Type': 'multipart/form-data',
            'token': window.localStorage.getItem('token')
        },
        method: 'POST',
        url: 'Admin/upload_oss',
        data: data
    });
    
}


