<template>
    <div class="root">
        <div class="left">
            <div :class="current_pos == 0 ? 'active' : ''" @click="changeBtn(0)">专辑列表</div>
            <div :class="current_pos == 1 ? 'active' : ''" @click="changeBtn(1)">添加</div>
        </div>

        <div class="right">
            <div class="list" v-show="current_pos == 0">
                <el-switch v-model="status" @change="changeStatus(status)"/> {{status ? '列表' : '回收站'}}
                <div class="content">
                    <div class="item" v-for="(item, index, key) in bannerList" :key="key">
                        <img :src="item.img" alt="">
                        <div class="title">{{item.title}}</div>
                        <div class="type" style="display:flex">
                            类型:
                            <div v-if="item.type == 1">专辑</div>
                            <div v-else-if="item.type == 2">壁纸</div>
                            <div v-else>链接</div>
                        </div>
                        <div class="type_info">
                            信息：
                            <div v-if="item.type == 1" style="color:red">专辑名称：{{item.album ? item.album.title : ''}}</div>
                            <div v-else-if="item.type == 2" style="color:green">壁纸名称：{{item.wallpaper ? item.wallpaper.name : ''}}</div>
                            <div v-else style="color:blue">地址链接：{{item.url}}</div>
                        </div>
                        
                        <div class="btn" type="primary" @click="editHandle(item)">修改</div>
                        <!-- <div class="btn" type="primary">详情</div> -->
                        <div class="btn" type="primary" @click="recyleHandle(item.id)">{{status ? '放入回收站' : '移除回收站'}}</div>
                    </div>
                </div>
            </div>
            
            <div class="add" v-show="current_pos == 1">
                <input type="file" @change="uploadImg">
                <img :src="file_url">
                <div>
                    banner标题：<textarea v-model="title" ></textarea>
                </div>
                <div class="type">   
                    banner类型
                    <input type="radio" v-model="type" value="1"/> <label>专辑</label>  
                    <input type="radio" v-model="type" value="2"/> <label>壁纸</label>  
                    <input type="radio" v-model="type" value="3"/> <label>url链接</label>  
                </div>

                <div style="height:10px"></div>
                <div class="album" v-show="type == 1">
                    请选择专辑
                    <select v-model="album_id">
                        <option disabled value="">请选择专辑</option>
                        <option v-for="(item, index, key) in albumList" :key="key" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>

                </div>

                <div class="wallpaper" v-show="type == 2">
                    请选择壁纸
                    <select v-model="wallpaper_id">
                        <option disabled value="">请选择壁纸</option>
                        <option v-for="(item, index, key) in wallpaperList" :key="key" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>

                </div>

                <div class="banner_url" v-show="type == 3">
                    请输入链接：<input type="text" v-model="url">
                </div>
                <div class="btn" @click="saveHandle">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import { useRouter} from "vue-router";
import { reactive, toRefs } from "vue";
import { ElMessage } from 'element-plus'
import {Upload, addBanner, BannerList, WallpaperList, AlbumList, BannerRecyle} from '../../network/index'


export default({
  setup() {
    const router = useRouter();
    
    const data = reactive({
      current_pos:0,
      file_url:'',
      file: {},
      title:"",
      type: 1,
      album_id:-1,
      wallpaper_id: -1,
      albumList: [],
      status: true,
      wallpaperList:[],
      url:'',
      bannerList:[]
    });

    const editHandle = (item) => {
        console.log(item);
        router.push('/bannerEdit?id='+ item.id);
    }

    const recyleHandle = (id) => {
        console.log(data.status);
        BannerRecyle({id: id, status:!data.status ? 1 : 0}).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('操作成功');
                bannerListHandle();
            } else {
                ElMessage.error(res.message);
            }
        });
    }

    const changeStatus = (status) => {
        console.log('状态:', status);
        bannerListHandle()
    }

    const albumListHandler = () => {
            AlbumList({status: 1}).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    data.albumList = res.data;
                } else {
                    ElMessage.error(res.message);
                }
            });
        }

    albumListHandler();
    WallpaperList({status: 1, allFlag: 1}).then((res) => {
        if (res.code == 1) {
            data.wallpaperList = res.data;
        } else {
            ElMessage.error(res.message);
        }
    });
    const bannerListHandle = () => {
        //banner列表
            BannerList({status: data.status ? 1 : 0}).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    data.bannerList = res.data;
                } else {
                    ElMessage.error(res.message);
                }
            });
    }
    bannerListHandle();
    const saveHandle = () => {
        console.log('提交保存');
        if (data.file_url == '') {
            ElMessage.error('请上传图片');
            return;
        }
        if (data.title == '') {
            ElMessage.error('请输入标题');
        }
        const addBannerHandler = (img)=> {
            const param = {
                type: data.type,
                album_id: data.album_id,
                wallpaper_id: data.wallpaper_id,
                img: img,
                url: data.url,
                title: data.title
            };
            addBanner(param).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    ElMessage.success('添加成功');
                    data.current_pos = 0;
                    data.status = true;
                    //更新数据列表
                    bannerListHandle();
                } else {
                    ElMessage.error(res.message);
                }
            });
        }
        //上传图片
        let formData = new FormData();
        formData.append('file', data.file);
        Upload(formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('上传成功');
                addBannerHandler(res.data.file_path);
            } else {
                ElMessage.error(res.message);
            }
            
            // data.img = e.data.file_path;
        });
    }
    const uploadImg = (e) => {
        const file = e.target.files[0];
        data.file = file;
        console.log('上传图片', file);
        // e.target.files[0];
        data.file_url = URL.createObjectURL(file);
        
    }
    const changeBtn = (pos)=> {
        data.current_pos = pos;
        if (data.current_pos == 0) {
            bannerListHandle();
        }
    }
    
    const token = window.localStorage.getItem('token');
    if (!token) {
          // alert('请重新登录');
          ElMessage.error('请重新登录');
          router.push('/login');
          // this.$router.push('/login');
          return;
    }
  
    return {
        editHandle,
        saveHandle,
        changeBtn,
        uploadImg,
        changeStatus,
        recyleHandle,
      ...toRefs(data),
    }
  },
})
</script>

<style scoped>
.root {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    height: 100%;
}
.root .left {
    flex: 1;
    /* height: 100%; */
    background: #6fd3ce;
}

.root .left div {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.root .left .active {
    background: #5db45e;
}

.root .right {
    flex: 5;
    background: #cdffc3;
}
.root .right .add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #333333;
}

.root .right .list {
    margin: 10px;
}
.root .right .list .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.root .right .list .item {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid salmon;
    margin: 10px;
}

.root .right .list .item .btn {
    width: 100%;
    height: 30px;
    background: #5db45e;
    margin-top: 3px;
    line-height: 30px;
    color: white;
}

.root .right .list .item .btn_active {
    background: blueviolet;
}

.root .right .list .item img {
    width: 150px;
    height: 60px;
    overflow: hidden;
}

.root .right .list .item .title {
    margin-top: 10px;
    font-size: 12px;
    color: #333333;
    margin-bottom: 10px;
}

.root .right .add img{
    width: 100px;
}

.root .right .add textarea {
    width: 300px;
    height: 100px;
    margin-top: 10px;
}
.root .right .btn {
    height: 40px;
    width: 300px;
    background: #5db45e;
    border-radius: 10px;
    margin: 10px;
    line-height: 40px;
    color: white;
    font-size: 14px;
    cursor: pointer;
} 

.type {
    color: red;
}

</style>