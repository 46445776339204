<template>
    <div class="root">
        <div class="left">
            <div :class="current_pos == 0 ? 'active' : ''" @click="changeBtn(0)">专辑列表</div>
            <div :class="current_pos == 1 ? 'active' : ''" @click="changeBtn(1)">添加</div>
        </div>

        <div class="right">
            <div class="list" v-show="current_pos == 0">
                <el-switch v-model="status" @change="changeStatus(status)"/> {{status ? '列表' : '回收站'}}
                <div class="content">
                    <div class="item" v-for="(item, index, key) in albumList" :key="key">
                        <img :src="item.img" alt="">
                        <div class="title">{{item.title}}</div>
                        <div>浏览量:{{item.browser_num}}</div>
                        <div>分享量:{{item.share_num}}</div>
                        <div>收藏量:{{item.collect_num}}</div>
                        <div class="btn" type="primary" @click="editHandle(item)">修改</div>
                        <!-- <div class="btn" type="primary">详情</div> -->
                        <div class="btn" type="primary" @click="recyleHandle(item.id)">{{status ? '放入回收站' : '移除回收站'}}</div>
                        <div class="btn" :class="item.hot == 1 ? 'btn_active' : ''" @click="hotHandle(item)">{{item.hot == 1 ? '推荐' : '不推荐'}} </div>
                    </div>
                </div>
            </div>
            
            <div class="add" v-show="current_pos == 1">
                <input type="file" @change="uploadImg">
                <img :src="file_url">
                <div>
                    专辑标题：<textarea v-model="title" ></textarea>
                </div>
                <div>
                    专辑描述：<textarea v-model="desc" ></textarea>
                </div>
                <div>
                    是否热门:<input type="checkbox" v-model="hot">
                </div>
                <div class="btn" @click="saveHandle">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import { useRouter} from "vue-router";
import { reactive, toRefs } from "vue";
import { ElMessage } from 'element-plus'
import {Upload, addAlbum, AlbumList, AlbumRecyle, AlbumHot} from '../../network/index'

export default({
  setup() {
    const router = useRouter();
    
    const data = reactive({
      current_pos:0,
      file_url:'',
      file: {},
      title:"",
      desc:"",
      hot:false,
      albumList: [],
      status: true
    });

    const editHandle = (item) => {
        console.log(item);
        router.push('/albumEdit?id='+ item.id);
    }

    const hotHandle = (item) => {
        AlbumHot({id: item.id, hot: item.hot == 1 ? 0 : 1}).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('操作成功');
                albumListHandler();
            } else {
                ElMessage.error(res.message);
            }
        });
    };
    const recyleHandle = (id) => {
        console.log(data.status);
        AlbumRecyle({id: id, status:!data.status ? 1 : 0}).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('操作成功');
                albumListHandler();
            } else {
                ElMessage.error(res.message);
            }
        });
    }

    const changeStatus = (status) => {
        console.log('状态:', status);
        albumListHandler();
    }

    const albumListHandler = () => {
            AlbumList({status:data.status ? 1 : 0}).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    data.albumList = res.data;
                } else {
                    ElMessage.error(res.message);
                }
            });
        }

    albumListHandler();
    
    const saveHandle = () => {
        console.log('提交保存');
        if (data.file_url == '') {
            ElMessage.error('请上传图片');
            return;
        }
        if (data.title == '') {
            ElMessage.error('请输入标题');
        }
        const addAlbumHandler = (img)=> {
            const param = {
                title: data.title,
                des: data.desc,
                img: img,
                hot:data.hot ? 1 : 0
            };
            addAlbum(param).then((res) => {
                console.log(res);
                if (res.code == 1) {
                    ElMessage.success('添加成功');
                    data.current_pos = 0;
                    data.status = true;
                    //更新数据列表
                    albumListHandler();
                } else {
                    ElMessage.error(res.message);
                }
            });
        }
        //上传图片
        let formData = new FormData();
        formData.append('file', data.file);
        Upload(formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('上传成功');
                addAlbumHandler(res.data.file_path);
            } else {
                ElMessage.error(res.message);
            }
            
            // data.img = e.data.file_path;
        });
    }
    const uploadImg = (e) => {
        const file = e.target.files[0];
        data.file = file;
        console.log('上传图片', file);
        // e.target.files[0];
        data.file_url = URL.createObjectURL(file);
        
    }
    const changeBtn = (pos)=> {
        data.current_pos = pos;
        if (data.current_pos == 0) {
            albumListHandler();
        }
    }
    
    const token = window.localStorage.getItem('token');
    if (!token) {
          // alert('请重新登录');
          ElMessage.error('请重新登录');
          router.push('/login');
          // this.$router.push('/login');
          return;
    }
  
    return {
        editHandle,
        saveHandle,
        changeBtn,
        uploadImg,
        changeStatus,
        recyleHandle,
        hotHandle,
      ...toRefs(data),
    }
  },
})
</script>

<style scoped>
.root {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    height: 100%;
}
.root .left {
    flex: 1;
    /* height: 100%; */
    background: #6fd3ce;
}

.root .left div {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.root .left .active {
    background: #5db45e;
}

.root .right {
    flex: 5;
    background: #cdffc3;
}
.root .right .add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #333333;
}

.root .right .list {
    margin: 10px;
}
.root .right .list .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.root .right .list .item {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid salmon;
    margin: 10px;
}

.root .right .list .item .btn {
    width: 100%;
    height: 30px;
    background: #5db45e;
    margin-top: 3px;
    line-height: 30px;
    color: white;
}

.root .right .list .item .btn_active {
    background: blueviolet;
}

.root .right .list .item img {
    width: 150px;
    height: 60px;
    overflow: hidden;
}

.root .right .list .item .title {
    margin-top: 10px;
    font-size: 12px;
    color: #333333;
    margin-bottom: 10px;
}

.root .right .add img{
    width: 100px;
}

.root .right .add textarea {
    width: 300px;
    height: 100px;
    margin-top: 10px;
}
.root .right .btn {
    height: 40px;
    width: 300px;
    background: #5db45e;
    border-radius: 10px;
    margin: 10px;
    line-height: 40px;
    color: white;
    font-size: 14px;
    cursor: pointer;
} 


</style>