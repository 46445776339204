<template>
    <div class="root">
        <div class="login">
            <div class="title">壁纸小屋管理系统</div>
            <input type="text" value="name" class="" placeholder="请输入用户名" v-model="name">
            <input type="password" value="password" class="input_password" placeholder="请输入密码" v-model="password">
            <div class="login_btn" @click="loginHandle">登录</div>
            <!-- <img src="" alt=""> -->

        </div>
    </div>
</template>
<script>

// import { useRouter} from "vue-router";
import { reactive, toRefs } from "vue";
// import { ElLoading } from 'element-plus'
import { ElMessage } from 'element-plus'
import {login} from '../../network/index.js'
import { useRouter} from "vue-router";

export default({
  setup() {
    const router = useRouter();
    const data = reactive({
      name:'',
      password:'',
    });
    const loginHandle = ()=>{
        // ElLoading.service({
        //     title: '登录中',
        //     fullscreen:false
        // });
        console.log('登录', data.name, data.password);    
        if (data.name == '') {
            ElMessage.error('用户名不能为空');
            return;
        }

        if (data.password == '') {
            ElMessage.error('密码不能为空');
        }
        const CryptoJS = require("crypto-js");
        const passwordMd5 = CryptoJS.MD5(data.password).toString().toLowerCase();
        login({name: data.name, password: passwordMd5}).then((res)=>{
            console.log(res);
            if (res.code == 1) {
                ElMessage.success('登录成功');
                const token = res.data.token;
                window.localStorage.setItem("token", token);
                router.push('/');
            } else {
                 ElMessage.error(res.message);
            }
        });
        
    }
  
    return{
      loginHandle,
      ...toRefs(data),
    }
  },
})
</script>
<style scoped>

body,div{
    margin: 0;
    padding: 0;
}
.root{
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    /* height: 300px; */
    /* background: white; */
    background-image: url('../../assets/login_bg.webp');
    background-size: cover; /* 背景图片覆盖整个页面 */
    background-repeat: no-repeat; /* 背景图片不重复 */
}

.root .login {
    /* width: 100px;
    height: 100px; */
    display: flex;
    flex-direction: column;
    
}
.root .login .title {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
   
}

.root .login input {
    width: 200px;
    height: 25px;
    color: #333333;
    border: 1px solid #e15502;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.root .login .input_password {
    margin-top: 15px;
}

.root .login .login_btn {
    width: 200px;
    padding: 8px 10px;
    margin-top: 10px;
    background: #e15502;
    border-radius: 100px;
    color: white;
    cursor: pointer;
}
</style>